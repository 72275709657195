import React from "react";
import { useOutsideClickWithRef } from "utils/useOutsideClick";
import { useResizeObserver } from "utils/useResizeObserver";
import { useFocusState } from "utils/useFocusState";
import { useCreateOverlay } from "utils/useCreateOverlay";

const useFixAndUnfixElementPosition = <T extends HTMLElement>(
	elementRef: React.RefObject<T>
) => {
	const [originalStyles, setOriginalStyles] = React.useState({});

	const captureCurrentStyles = () => {
		if (elementRef.current) {
			// Store the original styles before fixing the position
			setOriginalStyles({
				zIndex: elementRef.current.style.zIndex,
			});
		}
	};

	const fixElementPosition = React.useCallback(() => {
		if (elementRef.current) {
			Object.assign(elementRef.current.style, {
				zIndex: "10000", // Make sure it's above other content
			});
		}
	}, [elementRef]);

	const unfixElementPosition = React.useCallback(() => {
		if (elementRef.current) {
			// Revert to the original styles
			Object.assign(elementRef.current.style, originalStyles);
		}
	}, [elementRef, originalStyles]);

	return { fixElementPosition, unfixElementPosition, captureCurrentStyles };
};

export const useFocusElementOverBackground = <T extends HTMLElement>() => {
	// target element
	const ref = React.useRef<T>(null);
	// target element state
	const { focused, setFocused } = useFocusState(ref);
	// focus is lost when clicking outside the element
	useOutsideClickWithRef(ref, () => {
		setFocused(false);
	});
	// helpers to fix and unfix the element position
	const { fixElementPosition, unfixElementPosition, captureCurrentStyles } =
		useFixAndUnfixElementPosition(ref);

	// create/remove overlay and fix/unfix element position
	const { createOverlay, removeOverlay } = useCreateOverlay("focus-overlay");
	React.useEffect(() => {
		if (!focused) {
			removeOverlay();
			unfixElementPosition();
		} else {
			captureCurrentStyles();
			fixElementPosition();
			createOverlay();
		}
	}, [focused]);

	// "refix" the element to reset the target position
	const refixElementPosition = React.useCallback(() => {
		if (focused) {
			// removing fixed position allows the dom to recompute element's relative position
			unfixElementPosition();
			// use new relative position to fix the element again
			fixElementPosition();
		}
	}, [focused, fixElementPosition, unfixElementPosition]);
	// "refix" when the element is resized
	useResizeObserver(ref, refixElementPosition);
	// "refix" when the window is resized
	React.useEffect(() => {
		window.addEventListener("resize", refixElementPosition);
		return () => {
			window.removeEventListener("resize", refixElementPosition);
		};
	}, [refixElementPosition]);

	return { ref, focused, setFocused };
};
