import debounce from "debounce";
import { useEffect, useState } from "react";
import client from "./client";
import constants from "./constants";
import { sendErrorToWebhook } from "./sendWebhook";
import { AutocompleteItem } from "./types";

export const useAutocomplete = (
	query: string,
	preferredState: string = constants.DEFAULT_AUTOCOMPLETE_STATE
) => {
	const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);
	const [autoCompleteResults, setAutocompleteResults] = useState<
		AutocompleteItem[]
	>([]);

	useEffect(() => {
		let active = true;

		const fetchData = async (query: string) => {
			try {
				if (active) {
					setIsAutocompleteLoading(true);
					const res = await client.autocomplete({
						query: query,
						preferredState,
					});
					setAutocompleteResults(res.body);
					setIsAutocompleteLoading(false);
				}
			} catch (e: any) {
				console.error(e);
			}
		};

		const debouncedFetch = debounce(fetchData, 200);

		if (query && query.length > 1) {
			setIsAutocompleteLoading(true);
			debouncedFetch(query);
		} else {
			setAutocompleteResults([]);
			setIsAutocompleteLoading(false);
			debouncedFetch.clear();
		}

		return () => {
			active = false;
		};
	}, [query]);

	return { autoCompleteLoading: isAutocompleteLoading, autoCompleteResults };
};
