import { FC } from "react";
import TextField, { TextFieldProps } from "./TextField";
import MapPinIcon from "public/landing/map_pin_outline.svg";
import MagnifyingGlassIcon from "public/landing/magnifying_glass_rounded.svg";

interface SearchProps {
	handleRightIconClick?: () => void;
	showLeftIcon?: boolean;
	showRightIcon?: boolean;
}

const Search: FC<SearchProps & TextFieldProps> = ({
	handleRightIconClick,
	showLeftIcon = true,
	showRightIcon = true,
	...props
}) => {
	return (
		<div className="search-input-container">
			<TextField
				{...props}
				autoComplete="address-line1"
				name="address-line1"
				id="autocomplete-address-line1"
				className={props.className || "" + " search"}
				style={
					!showLeftIcon
						? {
								paddingLeft: "16px",
						  }
						: {}
				}
			/>
			{showLeftIcon && <MapPinIcon className="search-icon-left" />}
			{showRightIcon && (
				<MagnifyingGlassIcon
					onClick={handleRightIconClick}
					className="search-icon-right"
					aria-label="Search your savings on this property"
					tabIndex={0}
				/>
			)}
		</div>
	);
};

export default Search;
