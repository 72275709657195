import React from "react";

export const useResizeObserver = <T extends HTMLElement>(
	elementRef: React.RefObject<T>,
	onResize: (dimensions: { width?: number; height?: number }) => void
) => {
	React.useEffect(() => {
		const target = elementRef.current;
		if (!target) return;

		const resizeObserver = new ResizeObserver(entries => {
			if (!entries.length) return;
			const { width, height } = entries[0].contentRect;

			// Call the callback function with new dimensions
			onResize({ width, height });
		});

		resizeObserver.observe(target);

		// Cleanup function
		return () => resizeObserver.disconnect();
	}, [elementRef, onResize]); // Include onResize in the dependency array
};
