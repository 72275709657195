import React from "react";

export const useFocusState = <T extends HTMLElement>(
	elementRef: React.RefObject<T>
) => {
	const [focused, setFocused] = React.useState(false);

	const handler = React.useCallback(
		(e: MouseEvent) => {
			if (elementRef.current && e.target) {
				setFocused(true);
			}
		},
		[focused]
	);

	React.useEffect(() => {
		if (!elementRef.current) return;
		elementRef.current.addEventListener("click", handler);
		return () => {
			if (!elementRef.current) return;
			elementRef.current.removeEventListener("click", handler);
		};
	}, [handler]);

	return { focused, setFocused };
};
