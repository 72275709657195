export const useCreateOverlay = (id: string = "overlay") => {
	const createOverlay = () => {
		const div = document.createElement("div");
		div.id = id;
		div.style.position = "fixed";
		div.style.top = "0";
		div.style.left = "0";
		div.style.width = "100%";
		div.style.height = "100%";
		div.style.transition = "background-color 0.5s"; // Inline animation
		div.style.backgroundColor = "transparent"; // Initial transparent background
		div.style.zIndex = "9999";
		div.style.pointerEvents = "none";
		document.body.appendChild(div);
		setTimeout(() => {
			div.style.backgroundColor = "rgba(5, 50, 73, 0.75)"; // Transition to target background color
		}, 0);
	};

	const removeOverlay = () => {
		const overlay = document.getElementById(id);
		if (overlay) {
			document.body.removeChild(overlay);
		}
	};

	return { createOverlay, removeOverlay };
};
